import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/website/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Mermaid } from "src/components/Mermaid";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "architecture"
    }}>{`Architecture`}</h1>
    {
      /* prettier-ignore */
    }
    <Mermaid mdxType="Mermaid">
	sequenceDiagram; 
  Browser->>API: HTTP Request;
	API->>Database: SQL Query; 
  Database->>API: Rows of Results; 
  API->>Browser: JSON Response;
    </Mermaid>
    <h2 {...{
      "id": "the-stack"
    }}>{`The Stack`}</h2>
    <p>{`Multitenant, monolithic, 3-tier application with a MySQL database, Spring Boot HTTP API and React Single Page Application.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Component`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Language`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Implementation`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`DB `}{`(`}{`Database`}{`)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Relational`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SQL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MySQL `}{`(`}{`pronounced "my sequel"`}{`)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`API `}{`(`}{`Application Programming Interface`}{`)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`JSON over HTTP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Java`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Spring Boot`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`UI `}{`(`}{`User Interface`}{`)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SPA `}{`(`}{`Single Page Application`}{`)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Javascript`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`React`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "responsibilities"
    }}>{`Responsibilities`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Component`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Where it runs`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Storage & Retrieval`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Security`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Navigation`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`DB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Private server `}{`(`}{`cloud`}{`)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`API`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Public server `}{`(`}{`internet facing`}{`)`}{` `}{`(`}{`cloud`}{`)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`UI`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Internet browsers `}{`(`}{`desktop and mobile devices`}{`)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`You can see the separation in responsbility here but I also want to highlighting the sharing of responsbility with storing and retrieving data.`}</p>
    <h2 {...{
      "id": "tools-and-libraries"
    }}>{`Tools and Libraries`}</h2>
    <p>{`SQL, Java, Javascript, HTML and CSS are still the most popular technologies among developers in 2018. `}<a parentName="p" {...{
        "href": "https://insights.stackoverflow.com/survey/2018/#most-popular-technologies"
      }}>{`Most Popular Technologies, Developer Survey Results - Stack Overflow`}</a>{` and I know them pretty well.`}</p>
    <ul>
      <li parentName="ul">{`Database`}<ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://en.wikipedia.org/wiki/MySQL"
            }}>{`MySQL`}</a><ul parentName="li">
              <li parentName="ul">{`An older, common relational database that I have the most experience with.`}</li>
            </ul></li>
        </ul></li>
      <li parentName="ul">{`API`}<ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://en.wikipedia.org/wiki/Java_%28programming_language%29"
            }}>{`Java`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://spring.io/projects/spring-boot#overview"
            }}>{`Spring Boot`}</a><ul parentName="li">
              <li parentName="ul">{`An easy and opionated way to create Java applications.`}</li>
            </ul></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "http://hibernate.org/orm/"
            }}>{`Hibernate`}</a><ul parentName="li">
              <li parentName="ul">{`A popular `}<a parentName="li" {...{
                  "href": "https://stackoverflow.com/questions/1279613/what-is-an-orm-and-where-can-i-learn-more-about-it"
                }}>{`ORM`}</a>{` for Java that maps objects to and from the database.`}</li>
            </ul></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://spring.io/projects/spring-security"
            }}>{`Spring Security`}</a><ul parentName="li">
              <li parentName="ul">{`To authenticate users.`}</li>
            </ul></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://projects.spring.io/spring-data-jpa/"
            }}>{`Spring Data JPA`}</a><ul parentName="li">
              <li parentName="ul">{`Helpful abstraction for doing CRUD.`}</li>
            </ul></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "http://www.querydsl.com/"
            }}>{`Querydsl`}</a><ul parentName="li">
              <li parentName="ul">{`Creating typesafe and composable database queries even directly from http requests.`}</li>
            </ul></li>
        </ul></li>
      <li parentName="ul">{`UI`}<ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://reactjs.org/"
            }}>{`React`}</a><ul parentName="li">
              <li parentName="ul">{`Simple, powerful and popular.`}</li>
            </ul></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/facebook/create-react-app"
            }}>{`Create React App`}</a><ul parentName="li">
              <li parentName="ul">{`For an opionated, zero-configuration build process.`}</li>
            </ul></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://material-ui.com/"
            }}>{`Material-UI`}</a><ul parentName="li">
              <li parentName="ul">{`Implementation of the popular `}<a parentName="li" {...{
                  "href": "https://material.io/"
                }}>{`Google Material Design System`}</a>{` in React.`}</li>
            </ul></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://reacttraining.com/react-router/"
            }}>{`React Router V4`}</a><ul parentName="li">
              <li parentName="ul">{`URL management.`}</li>
            </ul></li>
        </ul></li>
    </ul>
    <h2 {...{
      "id": "development-tools"
    }}>{`Development Tools`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.atlassian.com/git/tutorials/what-is-version-control"
        }}>{`Version Control`}</a><ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://git-scm.com/"
            }}>{`Git`}</a><ul parentName="li">
              <li parentName="ul">{`Popular and works well.`}</li>
            </ul></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://danluu.com/monorepo/"
            }}>{`Monorepo`}</a><ul parentName="li">
              <li parentName="ul">{`For simplicity and synced changes across the application.`}</li>
            </ul></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "http://www.git-town.com/"
            }}>{`Git Town`}</a><ul parentName="li">
              <li parentName="ul">{`An abstraction over git to make it easier to do common things.`}</li>
            </ul></li>
        </ul></li>
      <li parentName="ul">{`Editors`}<ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://code.visualstudio.com/"
            }}>{`Visual Studio Code `}{`(`}{`vscode`}{`)`}</a><ul parentName="li">
              <li parentName="ul">{`Fast and popular editor.`}</li>
            </ul></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.sequelpro.com/"
            }}>{`Sequel Pro`}</a><ul parentName="li">
              <li parentName="ul">{`The best Mac app to manage MySQL databases.`}</li>
            </ul></li>
        </ul></li>
      <li parentName="ul">{`Deployment`}<ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "kubernetes-and-ci"
            }}>{`Kubernetes`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/features/actions"
            }}>{`GitHub Actions`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://flywaydb.org/"
            }}>{`Flyway`}</a><ul parentName="li">
              <li parentName="ul">{`for automated database migrations.`}</li>
            </ul></li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      